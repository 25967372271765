import { FC, PropsWithChildren, useEffect } from "react"
import { Toaster } from "react-hot-toast"
import Tracker from "@openreplay/tracker"
import { useAdminGetSession } from "medusa-react"

// @see https://vitejs.dev/guide/env-and-mode.html#env-variables
// const openReplayToken = import.meta.env.VITE_OPEN_REPLAY_TOKEN
const openReplayToken = undefined

const tracker = openReplayToken
  ? new Tracker({ projectKey: openReplayToken, __DISABLE_SECURE_MODE: true })
  : undefined

const BaseLayout: FC<PropsWithChildren> = ({ children }) => {
  const { user } = useAdminGetSession()

  useEffect(() => {
    tracker?.start()
  }, [])

  useEffect(() => {
    if (user?.email) {
      tracker?.setUserID(user.email)
    }
  }, [user])

  return (
    <>
      <Toaster
        containerStyle={{
          top: 74,
          left: 24,
          bottom: 24,
          right: 24,
        }}
      />
      {children}
    </>
  )
}

export default BaseLayout
